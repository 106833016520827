import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { toastr } from 'react-redux-toastr'
import _ from 'lodash'
import classnames from 'classnames'
import './Files.css'
import { getFile } from '../utils/network'
import {FilePlus, Download} from 'react-feather'
import { defaultT } from '../utils/i18n'
import { Table, TrashCell, AutoSizer, Column } from './DataTable'
import moment from "moment"
import { ProgressBar } from './ProgressBar/ProgressBar'
import axios from 'axios'
import {SortDirection} from 'react-virtualized/dist/commonjs/Table'

const sort = (ids, getValueForId, sortDirection) =>
    _.orderBy(
        ids,
        getValueForId,
        sortDirection === SortDirection.ASC ? 'asc' : 'desc'
    )

class Files extends Component {
    state = {
        sortBy: 'date',
        sortDirection: 'DESC',
        uploadState: 0,
        hovered: false,
    }

    handleResponse(data, t) {
        const { push, user, anonymous } = this.props
        if (data) {
            const userObject = !!anonymous ? {} : {user}
            push({
                ...data,
                ...userObject,
                date: moment().format('YYYY-MM-DD HH:mm')
            })
        }
        else {
            toastr.error(t('errorUploading'))
        }
    }

    handleFileUpload(event, t) {
        const files = event.target.files
        const file = files[0]
        let data = new FormData()
        const fileName = `${moment().format('YYYY-MM-DD_HH:mm:ss')}_${file.name}`
        const fileType = file.type
        const fileSize = file.size
        const chunkSize = 15 * 1024 * 1024 // 15MB
        let fileChunk = null, chunkNb = 0

        for (let start = 0; start < fileSize; start += chunkSize, chunkNb++) {
            if (start + chunkSize > fileSize) {
                fileChunk = file.slice(start, fileSize)
            }
            else {
                fileChunk = file.slice(start, start + chunkSize)
            }
            data.append('fileChunks', fileChunk, fileName)
        }
        data.append('fileType', fileType)
        data.append('fileSize', fileSize)
        data.append('chunkNb', chunkNb)

        const options = {
            headers: { 'Content-Type': 'multipart/form-data' },
            onUploadProgress: (progressEvent) => {
                const { loaded, total } = progressEvent
                let percent = Math.floor( (loaded * 100) / total )
                this.setState({ uploadState: percent })
            }
        }
        axios.post(`/${this.props.url}`, data, options)
            .then(res => {
                this.handleResponse(res.data, t)
                toastr.success(t('successFileUploading'))
                this.setState({ uploadState: 0 })
            })
            .catch(err => {
                const message = _.get(err, 'response.data')
                console.log(err)
                if(message) {
                    toastr.error(t(message))
                    this.setState({ uploadState: 0 })
                } else {
                    toastr.error(t('errorUploading'))
                    this.setState({ uploadState: 0 })
                }
                this.setState({ uploadState: 0 })
            })
        // Allow to re-upload the same file in a row
        event.target.value = null
    }

    findRowIndex = (rowId) => {
        const { allIds } = this.props

        return allIds.findIndex(id => id === rowId)
    }

    renderDeleteIcon = ({ rowData }) => {
        return <TrashCell onClick={() => {
            const index = this.findRowIndex(rowData.id)
            this.props.remove(index)
        }} />
    }
    renderDownloadIcon = () => (
        <Download size={16} className="Files-Icon"/>
    )

    handleDownload = (t, language, id, name, user, password, groupModel) => {
        toastr.info(t('downloading'))
        getFile({
            fileId: id,
            filename: name,
            password,
            language,
            groupModel
        })
    }

    render() {
        const { allIds = [], byId = {}, t = defaultT, language = 'fr', placeholder, noRowsMessage, editable, readOnly, displayEmpty, anonymous, removable, user, groupModel, style } = this.props

        const sortedIds = this.state.sortBy
            ? sort(allIds, id => byId[id][this.state.sortBy], this.state.sortDirection)
            : allIds

        const objects = sortedIds.map(id => byId[id])

        const columns = [<Column
            flexGrow={1}
            width={200}
            dataKey="filename"
            label={t('file', { capitalize: true })}
        />]

        if(!readOnly && !anonymous) {
            columns.push(<Column
                flexGrow={1}
                width={200}
                dataKey="user.name"
                label={t('user', { capitalize: true })}
                cellDataGetter={({ dataKey, rowData }) =>
                    _.get(rowData, dataKey)}
            />)
        }

        if(!readOnly) {
            columns.push(<Column
                flexGrow={1}
                width={200}
                dataKey="date"
                label={t('date', { capitalize: true })}
            />)
        }

        return (
            <div className="Files-container" style={style}>
                <div className="Files-panel-body">
                    <AutoSizer disableHeight>
                        {({ width }) => (
                            <Table
                                objects={objects}
                                sort={({ sortBy, sortDirection }) => {
                                    this.setState({
                                        sortBy,
                                        sortDirection
                                    })
                                }}
                                sortBy={this.state.sortBy}
                                sortDirection={this.state.sortDirection}
                                headerRowClassName="AccordionHeaderRow"
                                onRowClick={({ rowData }) => this.handleDownload(
                                    t, language, rowData.id, rowData.filename, user, rowData.password, groupModel
                                )}
                                maxRows={7}
                                width={width}
                                noRowsLabel={t(noRowsMessage || 'noRows')}
                            >
                                {columns}
                                <Column
                                    flexGrow={0}
                                    width={40}
                                    dataKey="id"
                                    label=""
                                    cellRenderer={this.renderDownloadIcon}
                                    className='DataTable-Table-row-column'
                                />
                                {
                                    editable && removable && <Column
                                        flexGrow={0}
                                        flexShrink={0}
                                        width={30}
                                        dataKey="id"
                                        label=""
                                        cellRenderer={this.renderDeleteIcon}
                                        className='DataTable-Table-row-column'
                                    />
                                }

                            </Table>
                        )}
                    </AutoSizer>
                </div>
                {
                    editable && <div className="Files-input-group">
                        <button
                            className={
                                classnames(
                                    "btn Files-button",
                                    {hovered: this.state.hovered}
                                )
                            }
                        >
                            <FilePlus size={16} className="Files-Icon" />
                            <span className="Files-button-Text">
                                {t(placeholder || 'addFiles', { capitalize: true })}
                            </span>
                        </button>
                        <input
                            className="Files-input"
                            type="file"
                            encType='multipart/form-data'
                            onMouseEnter={() => this.setState({hovered: true})}
                            onMouseLeave={() => this.setState({hovered: false})}
                            onDragOver={() => this.setState({hovered: true})}
                            onChange={event => this.handleFileUpload(event, t)}
                        />
                    </div>
                }
                { this.state.uploadState > 0 ?
                    <div className="uploadProgress">
                        { t('uploading') }
                        <ProgressBar percentage={this.state.uploadState} color='#E94E24'/>
                        {this.state.uploadState}%
                    </div>
                    : null
                }
            </div>
        )
    }
}

Files.propTypes = {
    push: PropTypes.func.isRequired,
    remove: PropTypes.func.isRequired,
    t: PropTypes.func
}

export default Files
