import {setFieldVisibility} from "../../../../../apps/KpModule/actions"
import {organizationActivities} from "../../staticEntities";

export const module_ = {
    object: 'Adhesion',
    name: 'File',
    tKey: 'mTitle_adhesionFile',
    newable: false,
    objectIdentifier: 'mail',
    category: {
        path: 'Adhésion',
        icon: 'folder'
    },
    viewMap: {
        dt: [
            {path: 'applicationNumber', tKey: "Référence"},
            {path: 'mail'},
            {path: 'firstname'},
            {path: 'lastname'},
            {path: 'status', type: "status", fieldPath: ['id', 'name', 'style'], width: 350},
            {path: 'createdAt', tKey: 'creationDate'},
        ],
        form: {
            fields: [
                {path: 'userSection', section: "administrativeSection", data: 'Président(e)', type: 'heading', hideLabel: true, style: {margin: '15px 5px 0', fontSize: 'larger', color: 'rgb(255 152 0)', padding: '10px 0'}},

                {path: 'civility', section: "administrativeSection"},
                {path: 'firstname', section: "administrativeSection"},
                {path: 'lastname', section: "administrativeSection"},
                {path: 'mail', section: "administrativeSection", disabled: true},
                {path: 'phone', type: 'phoneNumber', section: "administrativeSection"},

                {path: 'organizationSection', section: "administrativeSection", data: 'Association', type: 'heading', hideLabel: true, style: {margin: '15px 5px 0', fontSize: 'larger', color: 'rgb(255 152 0)', padding: '10px 0'}, disabled: true},

                {path: 'organizationName', tKey: 'name', section: "administrativeSection"},
                {path: 'organizationAddress', tKey: 'address', section: "administrativeSection"},
                {path: 'organizationAdditionalAddress', tKey: 'additionalAddress', section: "administrativeSection"},
                {path: 'organizationZipCode', tKey: 'zipCode', section: "administrativeSection"},
                {path: 'organizationCity', tKey: 'city', section: "administrativeSection"},
                {path: 'organizationCountry', tKey: 'country', section: "administrativeSection"},
                {path: 'organizationSize', tKey: "Taille de l'association", section: "administrativeSection"},
                {path: 'rna', tKey: "N° Registre National des Associations", section: "administrativeSection"},
                {path: "sheltersNumber", tKey: "Nombre de refuges", section: "administrativeSection"},
                {path: 'generalInterestRecognition', tKey: 'Notre association est reconnue d’intérêt général', section: "administrativeSection"},
                {path: 'publicUtilityRecognition', tKey: 'Notre association est reconnue d’utilité publique', section: "administrativeSection"},
                {path: 'hasEndowmentFund', tKey: 'Notre association dispose d’un fonds de dotations', section: "administrativeSection"},
                {path: 'hasDUERP', tKey: 'Notre association dispose d’un document unique des risques professionnels (DUERP) ', section: "administrativeSection"},
                {path: 'DUERPUpdateDate', tKey: 'Date de mise à jour DUERP', section: "administrativeSection"},

                {path: 'documentsSection', section: "administrativeSection", data: 'Documents', type: 'heading', hideLabel: true, style: {margin: '15px 5px 0', fontSize: 'larger', color: 'rgb(255 152 0)', padding: '10px 0'}, disabled: true},

                {path: 'contactID', tKey: 'Pièce d’identité Président(e)', fieldPath: ['id', 'filename', 'date', 'user.id', 'user.name'], type: "simpleFiles", section: "administrativeSection"},
                {path: 'situationNotice', tKey: 'Avis de situation de moins de trois mois', fieldPath: ['id', 'filename', 'date', 'user.id', 'user.name'], type: "simpleFiles", section: "administrativeSection"},
                {path: 'updatedStatus', tKey: 'Status à jour', fieldPath: ['id', 'filename', 'date', 'user.id', 'user.name'], type: "simpleFiles", section: "administrativeSection"},
                {path: 'balanceSheetsAndIncomeStatements', tKey: 'Bilans et comptes de résultats des trois derniers exercices', fieldPath: ['id', 'filename', 'date', 'user.id', 'user.name'], type: "simpleFiles", section: "administrativeSection"},
                {path: 'officialStatement', tKey: 'Déclaration officielle en Préfecture (au Tribunal Administratif pour Alsace et Moselle)', fieldPath: ['id', 'filename', 'date', 'user.id', 'user.name'], type: "simpleFiles", section: "administrativeSection"},
                {path: 'CERFA', tKey: 'CERFA et le récépissé d’enregistrement de la dernière liste des dirigeants déposée en Préfecture (au Tribunal Administratif pour Alsace et Moselle)', fieldPath: ['id', 'filename', 'date', 'user.id', 'user.name'], type: "simpleFiles", section: "administrativeSection"},
                {path: 'DDPVisitReports', tKey: 'Deux derniers rapports de visite de la DDP pour les refuges (Si Applicable)', fieldPath: ['id', 'filename', 'date', 'user.id', 'user.name'], type: "simpleFiles", section: "administrativeSection"},
                {path: 'sheltersPhotos', tKey: 'Photos de nos refuges (Si Applicable)', fieldPath: ['id', 'filename', 'date', 'user.id', 'user.name'], type: "simpleFiles", section: "administrativeSection"},
                {path: 'lastGeneralMeeting', tKey: 'Convocation et le procès-verbal de la dernière Assemblée Générale', fieldPath: ['id', 'filename', 'date', 'user.id', 'user.name'], type: "simpleFiles", section: "administrativeSection"},

                {path: 'structureSection', section: "technicalSection", data: 'Structure', type: 'heading', hideLabel: true, style: {margin: '15px 5px 0', fontSize: 'larger', color: 'rgb(255 152 0)', padding: '10px 0'}, disabled: true},

                {path: 'employeesNumber', section: "technicalSection", tKey: 'Nombre de salariés (ETP)', wholePositiveNumber: true, required: true},
                {path: 'electedOfficialsNumber', section: "technicalSection", tKey: 'Nombre d’élus', wholePositiveNumber: true, required: true},
                {path: 'volunteersNumber', section: "technicalSection", tKey: 'Nombre de bénévoles (Hormis élus et FA)', wholePositiveNumber: true, required: true},
                {path: 'investigatorsNumber', section: "technicalSection", tKey: 'Nombre d’enquêteurs', wholePositiveNumber: true, required: true},
                {
                    path: 'animalTypes',
                    section: 'technicalSection',
                    tKey: 'Types d’animaux dont vous vous occupez',
                    subscriptions: {
                        onChange: (newValue, oldValue, {module, store}) => {
                            console.log('newValue', newValue)
                            if(newValue) {
                                const animalTypeCapacityField = module.viewMap.form.fields.find(field => field.path === 'adhesionCapacityByAnimalType')
                                const animalTypeCapacityFieldValue = animalTypeCapacityField.getValue()
                                const computedValue = newValue?.map(animalType => {
                                    const animaTypeCapacity = animalTypeCapacityFieldValue.find(object => object.animalType.id === animalType.id)
                                    if(animaTypeCapacity) return animaTypeCapacity
                                    return {
                                        animalType,
                                        number: 0
                                    }
                                })
                                animalTypeCapacityField.setValue(computedValue || [])

                                const animalTypeCapacityByActivityField = module.viewMap.form.fields.find(field => field.path === 'adhesionCapacityByActivity')
                                const animalTypeCapacityByActivityFieldValue = animalTypeCapacityByActivityField.getValue()

                                const computedValue2 = _.flatMap(newValue, animalType => {
                                    return organizationActivities.map(activity => {
                                        const animaTypeCapacity = animalTypeCapacityByActivityFieldValue.find(object =>
                                            object.animalType.id === animalType.id && object.activity.id === activity.id
                                        )
                                        if(animaTypeCapacity) return animaTypeCapacity
                                        return {
                                            activity,
                                            animalType,
                                            number: 0
                                        }
                                    })
                                })

                                animalTypeCapacityByActivityField.setValue(computedValue2 || [])

                            }
                        }
                    }
                },
                {
                    path: 'adhesionCapacityByAnimalType', section: "technicalSection",
                    tKey: "ACACED",
                    type: 'dtObjects',
                    fields: [
                        'animalType',
                        {path: 'number', type: 'editText', width: 100},
                    ]
                },

                {path: 'activitiesSection', section: "technicalSection", data: 'Activités', type: 'heading', hideLabel: true, style: {margin: '15px 5px 0', fontSize: 'larger', color: 'rgb(255 152 0)', padding: '10px 0'}, disabled: true},

                {
                    path: 'adhesionCapacityByActivity', section: "technicalSection",
                    tKey: "Capacité par activité",
                    type: 'dtObjects',
                    defaultSortBy: 'activity',
                    defaultSortDirection: 'ASC',
                    fields: [
                        {path: 'activity', tKey: 'Activité'},
                        'animalType',
                        {path: 'number', type: 'editText', width: 100},
                    ]
                },

                {path: 'SheltersSection', section: "technicalSection", data: 'Refuges', type: 'heading', hideLabel: true, style: {margin: '15px 5px 0', fontSize: 'larger', color: 'rgb(255 152 0)', padding: '10px 0'}, disabled: true},

                {
                    path: 'adhesionShelters',
                    section: "technicalSection",
                    tKey: "Coordonnées",
                    //defaultSortBy: 'activity',
                    //defaultSortDirection: 'ASC',
                    viewMap: {
                        dt: [
                            'name',
                            'address',
                            'zipCode',
                            'city',
                        ],
                        form: [
                            'name',
                            'address',
                            'additionalAddress',
                            'zipCode',
                            'city',
                            'mail',
                            {path: 'phone', type: 'phoneNumber'},
                        ]
                    }
                },

                {path: 'documentsSection2', section: "technicalSection", data: 'Documents', type: 'heading', hideLabel: true, style: {margin: '15px 5px 0', fontSize: 'larger', color: 'rgb(255 152 0)', padding: '10px 0'}, disabled: true},

                {path: 'documents', section: "technicalSection", tKey: 'Documents divers'},

                {path: 'followUpSection', data: 'Suivi demande', type: 'heading', hideLabel: true, style: {margin: '15px 5px 0', fontSize: 'larger', color: 'rgb(255 152 0)', padding: '10px 0'}, disabled: true},

                {path: 'comments', tKeyText: "Suivi"},

                {path: 'applicationNumber', hidden: true},
                {path: 'status', hidden: true},
                {path: 'buttons', hidden: true}
            ],
            onOpen: ({ store, module }) => {
                const state = store.getState()
                const statusId = _.get(state, 'edit.object.data.status.id')
                const administrativeFields = module.viewMap.form.fields.filter(field => field.section === "administrativeSection")
                const technicalFields = module.viewMap.form.fields.filter(field => field.section === "technicalSection")

                administrativeFields.forEach(field => store.dispatch(setFieldVisibility(field.id, ['filed', 'updated', 'incomplete'].includes(statusId))))
                technicalFields.forEach(field => store.dispatch(setFieldVisibility(field.id, ['preValidated', 'filed2', 'updated2', 'adjourned', 'adjournedBD'].includes(statusId))))
            }
        }
    },
    filters: ['byUser']
}
