import {changeFieldDisabled, changeFieldProperty} from "../../../apps/KpModule/actions"
import Errors from '../../utils/Errors'

const async = require("async");
const moment = require("moment");
const _ = require("lodash");
const { setFieldVisibility } = require("../../../apps/KpModule/actions");

const getFieldsModuleForm = (fieldPaths, module) => {
    return fieldPaths.map(fieldPath => (
        module.viewMap.form.fields.find(
            field => field.path === fieldPath
        )
    ))
}

const todayISO = moment().format("YYYY-MM-DD");
const lastWeekISO = moment().subtract(7, "days").format("YYYY-MM-DD");

const cacheOnContext = function(context, callback) {
    // if alertsByConfigs is not yet computed, compute it

    if (!context.data || !context.data.alertsByConfigs) {
        // let t = new Date();
        userAlertsByAlertConfig(context, (e, alertsByConfigs) => {
            if(e) return callback(e);

            context.data = context.data ? context.data : {};
            _.assign(context.data, {alertsByConfigs});

            // const t2 = new Date();
            // console.log("alertConfig cache preparation duration: " + (t2 - t) + "ms");

            callback(null, context);
        });
    } else {
        callback(null, context);
    }
};

const userAlertsByAlertConfig = function(context, callback) {
    // creates a collection of {alertConfiguration: {..}, alerts: [..] }
    // it contains all alerts in date filter and that user has access

    async.waterfall(
        [
            async.constant(_.defaults({}, context)),

            (wfContext, callback) => global.app.S.User.getUserHabFunctions(
                wfContext.user,
                context,
                (e, userHabFunctions) => callback(e, {...wfContext, userHabFunctions})
            ),

            (wfContext, callback) => {
                // check for id on context, that means we need only the alerts of the alert configuration
                // otherwise we list alerts belonging to all alert configurations
                if(context.id && (_.get(context, "access.entity.name") === "AlertConfiguration")) {
                    global.app.S.AlertConfiguration.collection.findOne(
                        {_id: global.ObjectID(context.id)},
                        (e, alertConfiguration) => callback(e, {...wfContext, userAlertConfigurations: [alertConfiguration]})
                    )

                } else {
                    global.app.S.AlertConfiguration.getAlertConfigurationsForHabFunctions(
                        wfContext.userHabFunctions,
                        (e, userAlertConfigurations) => callback(e, {...wfContext, userAlertConfigurations})
                    )
                }
            },

            (wfContext, callback) => {

                async.map(wfContext.userAlertConfigurations, function(alertConfiguration, callback) {

                    try {
                        const query = global.app.S.AlertConfiguration.getAlertQueryFilter(alertConfiguration, wfContext);

                        global.app.S.Alert.collection
                            .find(query)
                            .toArray((e, alerts) => {
                                if(e) return callback(e);
                                callback(null, {alertConfiguration, alerts});
                            });
                    } catch(e) {
                        return callback(e);
                    }

                }, callback);
            }
        ],
        callback
    );
};

// add StoreAxis if groupAxes contain any SubStoreAxis
const groupAxesWithStore = alertConfiguration => {
    /*
    const groupAxisOfType = type => alertConfiguration.groupAxes.some(
        groupAxis => groupAxis.joinedEntity === type
    );

    const isSubStore = groupAxisOfType("SubStoreAxis");
    const isStore = groupAxisOfType("StoreAxis");
     */

    return _.compact([
        ...alertConfiguration.groupAxes
    ]);
};

export const dependencies = [
    {
        name: "AggOperator",
        type: "static",
        fields: [
            "mongoOperator",
            "tKey"
        ],
        objects: [
            {id: "000000000000000000000001", tKey: "sum", mongoOperator: "$sum"},
            {id: "000000000000000000000002", tKey: "average", mongoOperator: "$avg"},
            {id: "000000000000000000000003", tKey: "maximum", mongoOperator: "$max"},
            {id: "000000000000000000000004", tKey: "minimum", mongoOperator: "$min"}
        ]
    },

    {
        name: "AlertEngineOrder",
        type: "static",
        fields: [
            {path: "id", type: "string"},
            "tKey"
        ],
        objects: [
            {
                id: "aggregationRatioCondition",
                tKey: "Objet analysé",
                name: "Aggregation -> Ratio -> Condition"
            },
            {
                id: "ratioConditionAggregation",
                tKey: "Elémentaire",
                name: "Ratio -> Condition -> Aggregation"
            }
        ]
    },

    {
        name: "AlertField",
        type: "mongoInternal",
        fields: [
            {path: "type", type: "RuleType"},
            "ThemeJoin",
            "ThemeFieldType",
            {path: "themeLineCount", type: "boolean"},
            {
                path: "numeratorDelay",
                type: "integer"
            },
            {path: "denominatorTheme", type: "ThemeJoin", nullable: true},
            {path: "denominatorThemeFieldType", type: "ThemeFieldType", nullable: true},
            {path: "denominatorThemeLineCount", type: "boolean"},
            {
                path: "denominatorDelay",
                type: "integer"
            },
            "AggOperator",
            {type: "CondOperator", nullable: true},
            {path: "value", nullable: true, type: "decimal"},
            "AlertEngineOrder",
            {
                path: "themeFieldPath",
                fieldPath: ["themeJoin.completeCode", "themeFieldType.code"],
                f: function() {
                    return this.themeJoin
                        ? this.themeJoin.completeCode + "." + this.themeFieldType.code
                        : undefined;

                }
            },
            {
                path: "denominatorThemeFieldPath",
                fieldPath: ["denominatorTheme.completeCode", "denominatorThemeFieldType.code"],
                f: function() {
                    return this.denominatorTheme
                        ? this.denominatorTheme.completeCode + "." + this.denominatorThemeFieldType.code
                        : undefined;
                }
            },
            {
                path: "themeFieldPaths",
                lazy: true,
                fieldPath: ["themeFieldPath", "denominatorThemeFieldPath"],
                f: function() {
                    return _.compact([this.themeFieldPath, this.denominatorThemeFieldPath]);
                }
            },
            {
                path: "preGroupObjectForMongo",
                lazy: true,
                fieldPath: ["themeFieldPath", "themeFieldType.code", "themeLineCount", 'denominatorTheme', "denominatorThemeFieldPath", "denominatorThemeFieldType.code", "denominatorThemeLineCount"],
                f: function() {

                    return ["theme", "denominatorTheme"].reduce((acc, fieldPath) => {
                        return Object.assign(
                            acc,
                            this[`${fieldPath}FieldPath`]
                                ? {
                                    [this[`${fieldPath}FieldPath`].replace(/\./g,' ')]: this[`${fieldPath}FieldType`].code === 'Nb' && this[`${fieldPath}LineCount`]
                                        ? {"$first": "$ticketLineCount"}
                                        : {"$sum": `$${this[`${fieldPath}FieldPath`]}`}
                                }
                                : {}
                        )
                    }, {})

                }
            },
            {
                path: "themeFieldPathsWithNumeratorOrDenominator",
                lazy: true,
                fieldPath: ["themeFieldPath", "denominatorThemeFieldPath"],
                f: function() {
                    return _.compact([
                        this.themeFieldPath && `nominator-${this.themeFieldPath}`,
                        this.denominatorThemeFieldPath && `denominator-${this.denominatorThemeFieldPath}`
                    ]);
                }
            }
        ]
    }
]

export const entity = {
    name: "AlertConfiguration",
    facets: ["codeName", "description", "translatedField"],
    fields: [
        "TimeWindow",
        {path: "fromFiscalYearStart", type: "boolean"},
        {path: "filterFields", type: "Scope", link: "MTM"},
        {
            path: "preGroupAxes",
            type: "GroupAxis",
            link: "MTM",
            nullable: true
        },
        {
            path: "groupAxes",
            type: "GroupAxis",
            link: "MTM",
            nullable: true
        },
        {
            path: "habFunctionHabilitation",
            type: "HabFunction",
            link: {type: "MTM", oppositeField: {link: {deleteType: "block"}}}
        },
        {type: "AlertField", link: "OTM", notEmpty: true},
        {type: "Alert", link: {type: "OTM", deleteType: "cascade", oppositeField: {index: true}}},
        {type: "WeekDay", link: "MTO", nullable: true},
        {type: "MonthDay", link: "MTO", nullable: true},
        {path: "sendJournal", type: "boolean", nullable: true},
        {path: "dormant", type: "boolean", default: false},
        {
            path: "reason",
            type: "Reason",
            link: {type: "MTM", oppositeField: {link: {deleteType: "block"}}}
        },
        {
            type: "integer",
            path: "delay",
            notEmpty: true
        },
        {
            path: "filterFieldsRefs",
            lazy: true,
            fieldPath: ["filterFields.name"],
            $f: function(alertConfiguration, context, callback) {
                callback(null, alertConfiguration.filterFields.length ? alertConfiguration.filterFields.map(filterField => filterField.name).join(", ") : "All");
            }
        },
        {
            path: "groupAxesRefs",
            lazy: true,
            fieldPath: ["groupAxes.name"],
            $f: function(alertConfiguration, context, callback) {
                callback(null, alertConfiguration.groupAxes.map(groupeAxes => groupeAxes.name).join(", "));
            }
        },
        {
            path: "alertsInDatesForUser",
            lazy: true,
            $f: function (alertConfiguration, context, callback) {
                cacheOnContext(
                    context,
                    (e, context) => {

                        const alertsByConfig = _.find(
                            context.data.alertsByConfigs,
                            alertsByConfig => alertsByConfig.alertConfiguration._id.toString() === alertConfiguration.id
                        );

                        // const t = new Date();

                        if(!alertsByConfig || !alertsByConfig.alerts) {
                            callback(e, []);
                        }
                        else {
                            /*
                            const maxAlertNb = 3000;
                            if (alertsByConfig.alerts.length > maxAlertNb) {
                                return callback(new Errors.ValidationError(context.tc("maximumNumberOfAlert", {
                                    alert: translateName(alertConfiguration.name, _.get(context, "language.id")),
                                    maxNumber: maxAlertNb}))
                                );
                            }
                             */

                            global.app.S.Alert.find(
                                {
                                    group: context.group,
                                    fieldPath: ["id", "code", "dataPeriod", "lineCount", "uniqValuesByDelay", "meshWithoutGroupAxis", "meshWithoutGroupAxisLinks"],
                                    // fieldPath: [
                                    //     "id", "code", "dataPeriod", "lineCount", "values", "organizationRef", "storeAndSubStoreInfo", "productName",
                                    //     "alertConfiguration.alertFields.themeJoin.code", "alertConfiguration.alertFields.themeJoin.tName",
                                    //     "alertConfiguration.alertFields.themeFieldType.code",
                                    // ],
                                    query: {
                                        _id: {$in: _.map(alertsByConfig.alerts, "_id")}
                                    }
                                },
                                (e, alerts) => {
                                    // const t2 = new Date();
                                    // console.log("alerts in date query duration: " + (t2 - t) + "ms");

                                    callback(e, alerts);
                                }
                            )
                        }


                    }
                );
            }
        },
        {
            path: "alertNb",
            lazy: true,
            $f: function (object, context, callback) {
                cacheOnContext(
                    context,
                    (e, context) => {
                        const alertsByConfig = _.find(
                            context.data.alertsByConfigs,
                            alertsByConfig => alertsByConfig.alertConfiguration._id.toString() === object.id
                        );
                        const alerts = _.get(alertsByConfig, "alerts", []);
                        callback(null, alerts.length);
                    }
                );
            }
        },
        {
            path: "activationDay",
            fieldPath: ["weekDay", "monthDay"],
            f: function() {
                return this.weekDay
                    ? (this.monthDay ? this.weekDay.name + ", " + this.monthDay.name : this.weekDay.name)
                    : (this.monthDay ? this.monthDay.name : "Every Day");
            }
        },
        {
            path: "groupAxisName",
            lazy: true,
            fieldPath: ["groupAxes.id"],
            $f: (alertConfiguration, context, callback) => callback(
                null,
                groupAxesWithStore(alertConfiguration).map(
                    groupAxis => global.app.S.GroupAxis.translateName(groupAxis, context)
                ).join(" | ")
            )
        },
        {
            path: "themesKeysUniqByDelay",
            lazy: true,
            fieldPath: [
                "alertFields.themeJoin.completeCodeWithoutPrefix",
                "alertFields.themeFieldType.code",
                "alertFields.denominatorTheme.completeCodeWithoutPrefix",
                "alertFields.denominatorThemeFieldType.code"
            ],
            f: function(){
                return _(this.alertFields)
                    .flatMap((alertField, index) => [
                        {themeJoin: alertField.themeJoin, fieldType: alertField.themeFieldType, prefix: `${index}-numerator-theme`,  delay: alertField.numeratorDelay},
                        {themeJoin: alertField.denominatorTheme, fieldType: alertField.denominatorThemeFieldType, prefix: `${index}-denominator-theme`, delay: alertField.denominatorDelay}
                    ])
                    .filter("themeJoin")
                    .uniqBy(o => {
                        return o.themeJoin.code + o.fieldType.code + o.delay
                    })
                    .map(
                        o => {
                            return `${o.prefix}-${o.themeJoin.completeCodeWithoutPrefix}.${o.fieldType.code}`
                        }
                    )
                    .value();
            }
        },
        {
            path: "themeNames",
            lazy: true,
            $f: function (object, context, callback) {
                global.app.S.AlertConfiguration.get(
                    object.id,
                    {
                        group: context.group,
                        fieldPath: ["themesNamesWithDelay"],
                    },
                    (e, alertConf) => {
                        return callback(null, alertConf.themesNamesWithDelay.join(" | "));
                    }
                )
            }
        },
        {
            path: "brutSummedAlertsValues",
            lazy: true,
            $f: function (object, context, callback) {
                cacheOnContext(
                    context,
                    (e, context) => {
                        const alertsByConfig = _.find(
                            context.data.alertsByConfigs,
                            alertsByConfig => alertsByConfig.alertConfiguration._id.toString() === object.id
                        );
                        const alerts = _.get(alertsByConfig, "alerts", []);
                        const alertConfId = _.get(alertsByConfig, "alertConfiguration._id");


                        global.app.S.AlertConfiguration.get(
                            alertConfId.toString(),
                            {
                                group: context.group,
                                fieldPath: ["themesKeysUniqByDelay"],
                            },
                            (e, alertConf) => {
                                const result = alerts.reduce((acc, alert) => {
                                    return alertConf.themesKeysUniqByDelay.map((key, index) => acc[index] + _.get(alert, key, 0))

                                }, Array(alertConf.themesKeysUniqByDelay.length).fill(0))

                                callback(
                                    null,
                                    result
                                        .map(value => _.round(value))
                                        .join(' | ')
                                );
                            }
                        )
                    }
                );
            }
        },
        {
            path: "summedAlertsValues",
            lazy: true,
            $f: function (object, context, callback) {
                cacheOnContext(
                    context,
                    (e, context) => {
                        const alertsByConfig = _.find(
                            context.data.alertsByConfigs,
                            alertsByConfig => alertsByConfig.alertConfiguration._id.toString() === object.id
                        );
                        const alerts = _.get(alertsByConfig, "alerts", []);
                        const alertConfId = _.get(alertsByConfig, "alertConfiguration._id");


                        global.app.S.AlertConfiguration.get(
                            alertConfId.toString(),
                            {
                                group: context.group,
                                fieldPath: ["themesKeysUniqByDelay"],
                            },
                            (e, alertConf) => {
                                const result = alerts.reduce((acc, alert) => {
                                    return alertConf.themesKeysUniqByDelay.map((key, index) => acc[index] + _.get(alert, key, 0))

                                }, Array(alertConf.themesKeysUniqByDelay.length).fill(0))

                                callback(
                                    null,
                                    result
                                        .map(value => _.round(value))
                                        .reduce((acc, value, index) => ({...acc, [`indicator${index + 1}`]: value}), {})
                                );
                            }
                        )
                    }
                );
            }
        },
        {
            path: "themesNamesWithDelay",
            lazy: true,
            fieldPath: [
                "alertFields.themeJoin.completeName",
                "alertFields.themeFieldType.code",
                "alertFields.denominatorTheme.completeName",
                "alertFields.denominatorThemeFieldType.code"
            ],
            f: function() {
                return _(this.alertFields)
                    .flatMap(alertField => [
                        {themeJoin: alertField.themeJoin, fieldType: alertField.themeFieldType, delay: alertField.numeratorDelay},
                        {themeJoin: alertField.denominatorTheme, fieldType: alertField.denominatorThemeFieldType, delay: alertField.denominatorDelay}
                    ])
                    .filter("themeJoin")
                    .uniqBy(o => {
                        return o.themeJoin.code + o.fieldType.code + o.delay
                    })
                    .map(
                        o => {
                            return o.delay
                                ? `${o.themeJoin.completeName} > ${o.fieldType.code} with delay ${o.delay}`
                                : `${o.themeJoin.completeName} > ${o.fieldType.code}`
                        }
                    )
                    .value();
            }
        },
        {
            path: "themesNameWithDelay",
            lazy: true,
            fieldPath: ["themesNamesWithDelay"],
            f: function(){
                return this.themesNamesWithDelay.join(" | ");
            }
        },
        {
            path: "themesKeys",
            lazy: true,
            fieldPath: [
                "alertFields.themeJoin.completeCodeWithoutPrefix",
                "alertFields.themeFieldType.code",
                "alertFields.denominatorTheme.completeCodeWithoutPrefix",
                "alertFields.denominatorThemeFieldType.code"
            ],
            f: function(){
                return _(this.alertFields)
                    .flatMap((alertField, index) => [
                        {themeJoin: alertField.themeJoin, fieldType: alertField.themeFieldType, prefix: `${index}-numerator-theme`},
                        {themeJoin: alertField.denominatorTheme, fieldType: alertField.denominatorThemeFieldType, prefix: `${index}-denominator-theme`}
                    ])
                    .filter("themeJoin")
                    .map(
                        o => `${o.prefix}-${o.themeJoin.completeCodeWithoutPrefix}.${o.fieldType.code}`
                    )
                    .uniq()
                    .value();
            }
        },
        {
            path: "completeThemesNames",
            lazy: true,
            fieldPath: [
                "alertFields.themeJoin.completeName",
                "alertFields.themeFieldType.code",
                "alertFields.denominatorTheme.completeName",
                "alertFields.denominatorThemeFieldType.code"
            ],
            f: function(){
                return _(this.alertFields)
                    .flatMap(alertField => [
                        {themeJoin: alertField.themeJoin, fieldType: alertField.themeFieldType},
                        {themeJoin: alertField.denominatorTheme, fieldType: alertField.denominatorThemeFieldType}
                    ])
                    .filter("themeJoin")
                    .map(
                        o => `${o.themeJoin.completeName} > ${o.fieldType.code}`
                    )
                    .value()
            }
        },
        {
            path: "themesNames",
            lazy: true,
            fieldPath: [
                "alertFields.themeJoin.completeName",
                "alertFields.themeFieldType.code",
                "alertFields.denominatorTheme.completeName",
                "alertFields.denominatorThemeFieldType.code"
            ],
            f: function(){
                return _(this.alertFields)
                    .flatMap(alertField => [
                        {themeJoin: alertField.themeJoin, fieldType: alertField.themeFieldType},
                        {themeJoin: alertField.denominatorTheme, fieldType: alertField.denominatorThemeFieldType}
                    ])
                    .filter("themeJoin")
                    .map(
                        o => `${o.themeJoin.completeName} > ${o.fieldType.code}`
                    )
                    .uniq()
                    .value();
            }
        },
        {
            path: "themesName",
            lazy: true,
            fieldPath: ["themesNames"],
            f: function(){
                return this.themesNames.join(" | ");
            }
        },
        {
            path: "filterFieldsName",
            fieldPath: ["filterFields.name"],
            f: function(){
                return this.filterFields.length ? this.filterFields.map(filterField => filterField.name).join(", ") : "All";
            }
        },
        {
            path: "baseDay",
            fieldPath: ["delay"],
            lazy: true,
            $f: function (object, context, callback) {
                const baseDay = moment.utc(context.referenceDate).subtract(object.delay, "days").toDate();
                callback(null, baseDay);
            }
        },
        {
            path: "queryDates",
            fieldPath: ["timeWindow.quantity", "timeWindow.period"],
            lazy: true,
            $f: function (object, context, callback) {

                return global.app.S.GeneralSettings.collection.findOne(
                    {group: global.ObjectID(context.group.id)},
                    (error, generalSettings) => {
                        if(generalSettings && object.timeWindow.id === '00000000000000000000000d'){
                            const fiscalYearStartDate = `${fiscalYear.month}-${fiscalYear.monthNumber}`
                            const periodEnd = moment.utc(context.referenceDate)
                                .add(1, "days")
                                .subtract(generalSettings.delay, "days")

                            const fiscalYearStart = moment.utc(fiscalYearStartDate, 'MM-DD')

                            const periodStart = periodEnd.isSameOrAfter(fiscalYearStart)
                                ? fiscalYearStart
                                : fiscalYearStart.subtract(1, 'years')
                            return callback(null, {
                                $gte: periodStart,
                                $lt: periodEnd
                            })
                        }

                        return callback(null, {
                            $gte: moment.utc(context.referenceDate)
                                .add(1, "days")
                                .subtract(generalSettings.delay, "days")
                                .subtract(object.timeWindow.quantity, object.timeWindow.period),
                            $lt: moment.utc(context.referenceDate)
                                .add(1, "days")
                                .subtract(generalSettings.delay, "days")
                        });
                    }
                )
            }
        },
        {
            path: 'hasCalculatedData',
            $f: (alertConfiguration, context, callback) => {
                global.app.S.Alert.collection
                    .findOne(
                        {alertConfiguration: new global.ObjectID(alertConfiguration.id)},
                        (e, alert) => {
                            if (e) return callback(e)
                            callback(null, !!alert)
                        })
            }
        }
    ],
    filters: [
        {
            name: "sendJournal",
            query: function(context) {
                if(context.sendJournal) return {sendJournal: context.sendJournal};
            }
        },
        {
            name: "alertConfigurationsByHabFunctions",
            isDefault: false,
            async: true,
            query: function (context, callback) {
                global.app.S.User.getUserHabFunctions(
                    context.user,
                    context,
                    function (e, userHabFunctions) {
                        callback(
                            e,
                            {$or: [
                                    {habFunctionHabilitation: {$size: 0}},
                                    {habFunctionHabilitation: {$in: userHabFunctions.map(hf => new global.ObjectID(hf.id))}}
                                ]}
                        )
                    }
                )
            }
        },
        {
            name: "hasStoreAxis",
            isDefault: false,
            client: false,
            match: function (object) {
                return object.groupAxes.some(o => o.joinedEntity === "StoreAxis")
            }
        }
    ],
    ps: {
        context: [{
            $$u: function (context, callback) {
                if (this.options.accessType === "S" && context.restAction && context.restAction.crudType === "U") {
                    const fieldPathAlertConf = ["habFunctionHabilitation.organizationAxisJoin.joinedEntity"]
                    context.internalFieldPath.push(...fieldPathAlertConf);
                }
                callback(null, context);
            }
        }]
    },
    validateDelete: function (object, context, callback) {
        async.parallel([
            callback =>  global.app.S.StaticWorkflow.collection.deleteMany({alertConfiguration: global.ObjectID(object.id)}, callback),
            callback =>  global.app.S.StatusUser.collection.deleteMany({alertConfiguration: global.ObjectID(object.id)}, callback),
            callback =>  global.app.S.WorkflowConfigsByAlert.collection.deleteMany({alertConfiguration: global.ObjectID(object.id)}, callback),
            callback =>  global.app.S.WorkflowConfig.collection.deleteMany({ alertConfiguration: global.ObjectID(object.id) }, callback),
        ], (e) => {
            if(e) return callback(e)
            return callback(null, object)
        })
    },
    validateSave: function(alertConf, oldAlertConf, context, callback) {
        //const propertyChanged = kpf.propertyChangeComparator(alertConf, oldAlertConf);

        const groupAxes = alertConf.groupAxes;
        const habFunctionHabilitations = alertConf.habFunctionHabilitation;

        /*
        if(groupAxes.length > 2) {
            return callback(new Errors.ValidationError(context.tc("theyCantBeMoreThanTwoElements")));
        }
         */

        const orgAxisOccurences = _.filter(groupAxes, {joinedEntity: "OrganizationAxis"}).length;
        if( orgAxisOccurences > 1){
            return callback(new Errors.ValidationError(context.tc("theOrganizationalAxisMustBeSelectedOnce")));
        }

        if( _.filter(groupAxes, {joinedEntity: "FamilyAxis"}).length > 1){
            return callback(new Errors.ValidationError(context.tc("theFamilyAxisMustBeSelectedOnce")));
        }

        if(orgAxisOccurences === 1 && groupAxes.some(ga => _.includes(["SubStoreAxis", "StoreAxis"], ga.joinedEntity))){
            return callback(new Errors.ValidationError(context.tc("theOrganizationalAxisCantbeSelectedWhithStoreAxisOrSubStoreAxis")));
        }


        if( groupAxes.length > 1 &&
            groupAxes.every(ga => _.includes(["FamilyAxis", "ProductAxis"], ga.joinedEntity)) ){

            return callback(new Errors.ValidationError(context.tc("theFamilyAxisCantBeSelectedWithProduct")));
        }

        if( groupAxes.length === 1 &&
            _.includes(["ProductAxis", "FamilyAxis"], groupAxes[0].joinedEntity) &&
            habFunctionHabilitations.some(hFH => hFH.organizationAxisJoin.joinedEntity !== "CentralAxis") ) {

            return callback(new Errors.ValidationError(context.tc("youCanUseOnlyCentralHabilitation")));
        }

        if( groupAxes.some(ga => _.includes(["OrganizationAxis"], ga.joinedEntity)) &&
            habFunctionHabilitations.some(hFH => _.includes(["StoreAxis"], hFH.organizationAxisJoin.joinedEntity)) ) {

            return callback(new Errors.ValidationError(context.tc("youCantUseStoreAxisHabilitation")));
        }

        const alertFields = alertConf.alertFields;
        if(alertFields.some(af => af.denominatorTheme && !af.denominatorThemeFieldType)){
            return callback(new Errors.ValidationError(context.tc("theDenominatorFieldIsRequiredWhenDenominatorIsSelected")));
        }

        /*
        if (!alertConf.reason.some(reason => reason.automatic)) {
            return callback(new Errors.ValidationError(context.tc("youMustChooseTheAutomaticReason")));
        }
         */

        callback();
    },
    beforeSave: async function(alertConf, oldAlertConf, context, callback) {
        const action = context.restAction && context.restAction.crudType
        if (action === 'C') {
            const automaticReason = await global.app.S.Reason.collection.findOne({automatic: true})
            if(automaticReason) {
                alertConf.reason = [{id: automaticReason._id.toString()}]
            }
            return callback(null, alertConf, oldAlertConf)
        }
        callback(null, alertConf, oldAlertConf)
    },
    getAlertConfigurationsForHabFunctions: function(uHabFuncs, callback) {
        this.collection
            .find({
                $or: [
                    //{habFunctionHabilitation: {$size: 0}},
                    {habFunctionHabilitation: {$in: uHabFuncs.map(hf => global.ObjectID(hf.id))}}
                ]
            })
            .toArray(callback);
    },

    getAlertQueryFilter: function(alertConfiguration, context) {

        const habFunctionsByType = _(alertConfiguration.habFunctionHabilitation)
            .map(
                habFunction => _.find(context.userHabFunctions, {id: habFunction.toString()})
            )
            .compact()
            .groupBy(
                habFunction => _.lowerFirst(habFunction.organizationAxisJoin.joinedEntity)
            )
            .value();

        const storeAndOrganizationQuery = function() {
            if (!habFunctionsByType.centralAxis && (habFunctionsByType.storeAxis || habFunctionsByType.organizationAxis)) {
                const uStoreDbRefsInAlertConfig = _(habFunctionsByType.storeAxis || [])
                    .flatMap("habilitations")
                    .flatMap("organizationsJoin")
                    .map(oj => global.ObjectID(oj.id))
                    .value();

                const uOrganizationDbRefsInAlertConfig = _(habFunctionsByType.organizationAxis || [])
                    .flatMap("habilitations")
                    .flatMap("organizationsJoin")
                    .map(oj => global.ObjectID(oj.id))
                    .value();

                return {$or: _.compact([
                        uOrganizationDbRefsInAlertConfig.length && {organizations: {$in: uOrganizationDbRefsInAlertConfig}},
                        uStoreDbRefsInAlertConfig.length && {store: {$in: uStoreDbRefsInAlertConfig}}
                    ])};

            } else {
                return {};
            }
        }();

        let dates;

        if (_.get(context, "data.period[0]") && _.get(context, "data.period[1]")) {
            dates = context.data.period;
        } else {
            console.warn("Dates in context not found!");
            dates = ["2022-06-30", "2022-06-30"];
        }

        dates = dates.map(
            date => moment.utc(date.trim(), "YYYY-MM-DD")
        );

        const baseQuery = {
            alertConfiguration: global.ObjectID(alertConfiguration._id),
            /*
            "date.gte": {
                $gte: dates[0].toDate()
            },
             */
            "date.lt": {
                $gt: dates[0].toDate(),
                $lte: dates[1].add(1, "days").toDate()
            },
            group: global.ObjectID(context.group.id)
        };

        return _.defaults(baseQuery, storeAndOrganizationQuery);
    },
    groupAxesWithStore,
    find: function(context, callback) {
        this.prepareContext(context, 'L', (error, context) => {
            if (error) return callback(error)

            if(
                _.get(context, 'module.name') === 'Alerts' &&
                _.get(context, 'module.useSocketsOnFind')
            ){
                const socketType = _.get(context, 'clientContext.accessId').endsWith('chart')
                    ? 'Chart'
                    : 'DT'
                global.app.S.AlertConfiguration.defaultFind(
                    context,
                    (e, alertConfigs) => {
                        if(e) {
                            global.ioSocket.emit(
                                `fetch${socketType}-failure-${context.user.id}${context.clientContext.accessId}`,
                                {error: e.message}
                            )
                        } else {
                            global.ioSocket.emit(
                                `fetch${socketType}-success-${context.user.id}${context.clientContext.accessId}`,
                                alertConfigs
                            )

                        }
                    })
                callback(null, [])
            } else {
                global.app.S.AlertConfiguration.defaultFind(context, (e, alertConfigs) => {
                    if(e) return callback(e);
                    callback(null, alertConfigs)
                })
            }
        })
    },
    // get: function(id, context, callback) {
    //     this.prepareContext(context, 'R', (error, context) => {
    //         if (error) callback(error)
    //         else {
    //             getElement(id, context)
    //                 .then(object => callback(null, object))
    //                 .catch(error => callback(error))
    //         }
    //     })
    // }
}

export const module_ = {
    object: "AlertConfiguration",
    tKey: "mTitle_admin_alert",
    deletionConfirmationMessage: 'alertConfigurationDeletionConfirmationMessage',
    name: "AlertConfiguration",
    category: {
        path: 'setting',
        icon: 'settings'
    },
    defaultSortBy : "name",
    defaultSortDirection : "ASC",
    viewMap: {
        dt: [
            {path: "name", type: "translatedText", width: 400},
            {path: "filterFieldsName", tKey: "dataPerimeter"},
            {path: "groupAxisName", tKey: "analysedObject"},
            {path: "timeWindow", tKey: "periodicity", display: "tKey", translate: true, width: 100},
            {path: "dormant", width: 50},
            //{path: "delay", tKey: "timeLagInDays", width: 120},
            {path: "habFunctionHabilitation", tKey: "profile(s)", translateName: true, initiallyNotVisible: true}
        ],
        form: {
            fields: [
                "code",
                {path: "name", type: "textarea", required: true},
                "description",
                {path: "filterFields", tKey: "dataPerimeter", display: "completeName", required: false},
                {path: "groupAxes", tKey: "analysedObject", display: "tName", required: true},
                {
                    path: "preGroupAxes",
                    tKey: "groupedBy",
                    display: "tName",
                    subscriptions: {
                        onChange: (newValue, oldValue, {store}) => {
                        }
                    }
                },
                {
                    path: "timeWindow",
                    tKey: "dataPeriod",
                    display: "tKey",
                    translate: true,
                    clearable: false,
                    sortList: false,
                    default: {id: "00000000000000000000000a"},
                    subscriptions: {
                        onChange: (newValue, oldValue, {store}) => {

                            const tKeyByValueId = {
                                "00000000000000000000000a": "denominatorDelayByDay",
                                "00000000000000000000000b": "denominatorDelayByWeek",
                                "00000000000000000000000c": "denominatorDelayByMonth",
                                "00000000000000000000000d": "denominatorDelayByFiscalYear"
                            }
                            if (newValue) {
                                store.dispatch(changeFieldProperty("e_alertFields.e_denominatorDelay", "tKey", tKeyByValueId[newValue.id] ))
                            }
                        }
                    }
                },
                // {path: "sendJournal", tKey: "includeInJournal"},
                {
                    path: "alertFields",
                    tKey: "rule_plural",
                    required: true,
                    removable: true,
                    viewMap: {
                        dt: [
                            {path: "themeJoin", tKey: "numerator", display: "completeName"},
                            {path: "themeFieldType", display: "tKey", translate: true, tKey: "nature"},
                            {path: "numeratorDelay"},
                            {path: "denominatorTheme", tKey: "denominator", display: "completeName"},
                            {path: "denominatorThemeFieldType", display: "tKey", translate: true, tKey: "amountOrNumber"},
                            {path: "denominatorDelay"},
                            {path: "aggOperator", display: "tKey", translate: true, tKey: "operation"},
                            {path: "condOperator", display: "name", translate: true, tKey: "condition"},
                            {path: "value", tKey: "conditionValue"},
                            {path: "alertEngineOrder", display: "tKey", translate: true, tKey: "computationOrder"}
                        ],
                        form: [
                            {
                                path: "type",
                                type: 'toggle',
                                sortList: false,
                                subscriptions: {
                                    onChange: (newValue, oldValue, {module, store}) => {
                                        const preGroupAxesField = module.viewMap.form.fields.find(field => field.path === "preGroupAxes")
                                        const alertFieldsField = module.viewMap.form.fields.find(field => field.path === "alertFields")
                                        const getField = path => alertFieldsField.viewMap.form.fields.find(field => field.path === path )
                                        const simpleRuleFields = ["themeJoin", "themeFieldType", "aggOperator", "condOperator", "value", "alertEngineOrder"].map(path => getField(path))
                                        const ratioRuleFields = ["denominatorTheme", "denominatorThemeFieldType", "denominatorDelay"].map(path => getField(path))

                                        simpleRuleFields.forEach(field => store.dispatch(setFieldVisibility(field.id, newValue && ["simple", "ratio"].includes(newValue.id))))
                                        ratioRuleFields.forEach(field => store.dispatch(setFieldVisibility(field.id, newValue?.id === "ratio")))

                                        const preGroupAxes = preGroupAxesField.getValue()
                                        store.dispatch(setFieldVisibility("e_alertFields.e_themeLineCount", !!preGroupAxes?.length && newValue && ["simple", "ratio"].includes(newValue.id)))
                                        store.dispatch(setFieldVisibility("e_alertFields.e_denominatorThemeLineCount", !!preGroupAxes?.length && newValue?.id === "ratio"))

                                        store.dispatch(changeFieldProperty("e_alertFields.e_themeJoin", "tKey", newValue?.id === "simple" ? "indicator" : "numerator"))

                                    }
                                }
                            },
                            {path: "themeJoin", tKey: "numerator", display: "completeName", hidden: true},
                            {path: "themeFieldType", display: "tKey", translate: true, tKey: "amountOrNumber", type: 'toggle', default: {id: "000000000000000000000101"}, hidden: true},
                            {path: "themeLineCount", tKey: "count", hidden: true},
                            {path: "numeratorDelay", wholePositiveNumber: true, default: 0, hidden: true},
                            {path: "denominatorTheme", tKey: "denominator", display: "completeName", required: true, hidden: true},
                            {path: "denominatorThemeFieldType", display: "tKey", translate: true, tKey: "amountOrNumber", type: 'toggle', default: {id: "000000000000000000000101"}, hidden: true},
                            {path: "denominatorThemeLineCount", tKey: "count", hidden: true},
                            {path: "denominatorDelay", tKey: "denominatorPeriodDelay", wholePositiveNumber: true, default: 0, hidden: true},

                            {path: "aggOperator", display: "tKey", translate: true, tKey: "operation", default: {id: "000000000000000000000001"}, hidden: true},
                            {path: "condOperator", display: "name", translate: true, tKey: "condition", required: true, hidden: true},
                            {path: "value", required: true, default: 0, hidden: true},
                            {path: "alertEngineOrder", display: "tKey", translate: true, tKey: "computationOrder", hideLabel: false, default: {id: "ratioConditionAggregation"}, hidden: true}
                        ]
                    }
                },
                {path: "habFunctionHabilitation", translateName: true, tKey: "habilitation", required: false},
                {path: "reason", hidden: true},
                {path: 'hasCalculatedData', hidden: true},
                "dormant"
            ],
            onOpen: ({ store }) => {
                const state = store.getState()
                //const objectMode = state.ui.objectMode
                const hasCalculatedData = state.edit.object.data.hasCalculatedData

                const fields = [
                    'e_code', 'e_filterFields', 'e_groupAxes',
                    'e_timeWindow', 'e_alertFields'
                ]
                fields.forEach(field => store.dispatch(changeFieldDisabled(field, hasCalculatedData)))
            }
        }
    }
}

export const alertModule = {
    object: "AlertConfiguration",
    tKey: "mTitle_alertConfiguration",
    name: "Alerts",
    category: {
        path: "tracking",
        icon: 'briefcase'
    },
    defaultSortBy: "alertNb",
    defaultSortDirection: "DESC",
    chart: {
        type: "donutChart",
        keys: ["tName"],
        data: 'alertNb'
    },
    newable: false,
    updatable: false,
    removable: false,
    useSocketsOnFind: true,
    viewMap: {
        dt: [
            {path: "tName", tKey: "alertType", width: 300 },
            {path: "timeWindow", tKey: "periodicity", display: "tKey", width: 100},
            {path: "groupAxes", tKey: "analyzedObject", display: "tName", width: 100},
            {path: "alertNb", tKey: "number", width: 25},
            {path: "themeNames", tKey: "indicator_plural"},
            {path: "summedAlertsValues", tKey: "totalValue", type: "number", dynamic: true, tooltip: true, disableColumnsSort: true, width: 80}
        ],
        form: [
            {path: "tName", tKey: "alertType", writable: false},
            {path: "groupAxisName", tKey: "analyzedObject"},
            {path: "themesNameWithDelay", tKey: "indicator_plural"},
            {path: "alertNb", tKey: "number"},
            {path: "brutSummedAlertsValues", tKey: "totalIndicatorsValue"},
            {
                path: "alertsInDatesForUser",
                tKey: "alert_plural",
                type: "dtObjects",
                applyBoard: true,
                maxRows: 20,
                // options: {searchBar: true},
                fields: [
                    {path: "dataPeriod", tKey: "period", width: 90},
                    {path: "meshWithoutGroupAxisLinks", tKey: "elements", type: "links", width: 380},
                    {path: "uniqValuesByDelay", tKey: "values", width: 40, dynamic: true, type: "customized", tooltip: true}
                ],
                // mField: {
                //     reloadList: true,
                // }
            }
        ],
        chart: ["tName", "alertNb"]
    },
    filters: [
        {
            type: "dateRange",
            default: [lastWeekISO, todayISO],
            path: "period",
            client: true,
            async: true,
            query: (context, callback) => {
                cacheOnContext(
                    context,
                    (e, context) => {
                        if (e) return callback(e);

                        const alertConfigIdsWithAlerts = _(context.data.alertsByConfigs)
                            .map(alertsByConfig => (
                                {
                                    _id: alertsByConfig.alertConfiguration._id,
                                    count: alertsByConfig.alerts.length
                                }
                            ))
                            .filter(idAndCount => idAndCount.count > 0)
                            .map("_id")
                            .value();

                        callback(e, {
                            _id: {$in: alertConfigIdsWithAlerts}
                        });
                    }
                );
            }
        }
    ]
}
