import React from 'react'
import { Field } from 'redux-form'
import { StyledInput } from '../../../../components/Form/StyledInput'
import getFormValidations from './formValidations'

// binding for redux-form
const Input = ({ input, meta:{touched, error}, required, path, editable, disabled, placeholder, wholePositiveNumber, wholePositiveNumberStrict, t }) => {
    const min = wholePositiveNumberStrict
        ? 1
        : wholePositiveNumber ? 0 : undefined
    const translatedError = t(error)
    return <StyledInput {...input} path={path} required={required} type="number" touched={touched} error={translatedError} disabled={disabled || !editable} placeholder={t(placeholder)} min={min} t={t} />
}

// the binding function has to be outside to avoid re-rendering
// https://github.com/erikras/redux-form/issues/1094

const FormNumberField = ({ field, t, objectMode }) => {
    const validations = getFormValidations(field, t)
    return (
        <Field
            name={field.path}
            path={field.tKey || field.path}
            required={field.required}
            component={Input}
            placeholder={field.placeholder}
            validate={validations}
            normalize={(value) => field.uppercase ? value.toUpperCase() : value}
            disabled={field.disabled || !field.writable}
            editable={!field.editable ? objectMode === 'newObject' : field.editable}
            wholePositiveNumberStrict={field.wholePositiveNumberStrict}
            t={t}
        />
    )
}

export default FormNumberField
