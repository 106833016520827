// const {objectIsNew, objectIsNotNew} = require("../../utils/kp3Utils");
import {generateFetchFieldListAction} from "../../../apps/KpModule/actions/api";
import {changeFieldDisabled} from "../../../apps/KpModule/actions";
import async from "async";
import moment from "moment";

const _ = require("lodash");
const { setFieldVisibility } = require("../../../apps/KpModule/actions");
const Errors = require('../../utils/Errors').default

const getFieldsModuleForm = (fieldPaths, module) => {
    return fieldPaths.map(fieldPath => (
        module.viewMap.form.fields.find(
            field => field.path === fieldPath
        )
    ))
}

export const dependencies = [
    {
        name: "ScoringField",
        type: "mongoInternal",
        fields: [
            {path: "numeratorTheme", type: "ThemeJoin", link: "MTO"},
            {path: "numeratorFieldType", type: "ThemeFieldType", link: "MTO"},
            {path: "denominatorTheme", type: "ThemeJoin", link: "MTO", nullable: true},
            {path: "denominatorFieldType", type: "ThemeFieldType", link: "MTO", nullable: true},
            {path: "lookupGrowth", type: "boolean", default: true},
            {path: "coefficient", type: "decimal"}
        ]
    }
]

export const entity = {
    name: "ScoringConfig",
    facets: ["description", "translatedField"],
    fields: [
        {path: "code", notEmpty: true, unique: true},
        {path: "name", notEmpty: true, unique: true},
        "description",
        {
            path: 'scoredElements',
            type: "GroupAxis",
            link: "MTM",
        },
        {path: "filterFields", type: "Scope", link: "MTM", nullable: true},
        {type: "ComparisonAxis", link: "MTO", nullable: true},
        {type: "TimeWindow", link: "MTO"},
        {path: "fromFiscalYearStart", type: "boolean"},
        {
            path: "fiscalYearStart",
            $f: function (object, context, callback) {
                global.app.S.GeneralSettings.collection
                    .findOne(
                        {group: global.ObjectID(context.group.id)},
                        (e, generalSettings) => {
                            if(!generalSettings) return callback(null,  moment.utc('01-01', 'MM-DD'))
                            return callback(null, moment.utc(`${generalSettings.month}-${generalSettings.monthNumber}`, 'MM-DD'))
                        }
                    )
            }
        },
        {type: "WeekDay", link: "MTO", nullable: true},
        {type: "MonthDay", link: "MTO", nullable: true},
        {path: "delay", type: "integer"},
        {path: "sendJournal", type: "boolean", nullable: true},
        {path: "dormant", type: "boolean", default: false},
        {
            path: "habFunctions",
            type: "HabFunction",
            link: {type: "MTM", oppositeField: {link: {deleteType: "block"}}},
            nullable: true
        },
        {
            type: "ScoringField",
            link: "OTM",
            notEmpty: true,
            ps: {
                object: [
                    {
                        $v: function (scoringFields) {
                            if (_.some(scoringFields, value => value.coefficient < 1)) return new Errors.ValidationError(this.options.context.tc("M_S.coefficientsMustBePositive"));
                        }
                    }
                ]
            }
        },
        {
            path: "filterFieldsName",
            fieldPath: ["filterFields.name"],
            f: function(){
                return this.filterFields.length ? this.filterFields.map(filterField => filterField.name).join(", ") : "All";
            }
        },
        {
            path: "scoredElementsSources",
            fieldPath: ["scoredElements.joinedEntity"],
            f: function () { return this.scoredElements.map(scoredElement => scoredElement.joinedEntity) }
        },
        {
            path: "activationDay",
            fieldPath: ["weekDay", "monthDay"],
            f: function() {
                return this.weekDay ? (this.monthDay ? this.weekDay.name + ", " + this.monthDay.name : this.weekDay.name) : (this.monthDay ? this.monthDay.name : "Every Day")
            }
        },
        {
            path: 'hasCalculatedData',
            $f: (scoringConfig, context, callback) => {
                global.app.S.Scoring.collection
                    .findOne(
                        {scoringConfig: new global.ObjectID(scoringConfig.id)},
                        (e, scoring) => {
                            if (e) return callback(e)
                            callback(null, !!scoring)
                        })
            }
        }
    ],
    validateDelete: function (object, context, callback) {
        async.parallel([
            callback =>  global.app.S.ScoreLine.collection.deleteMany({scoringConfig: global.ObjectID(object.id)}, callback),
            callback =>  global.app.S.Scoring.collection.deleteMany({ scoringConfig: global.ObjectID(object.id) }, callback),
        ], (e) => {
            if(e) return callback(e)
            return callback(null, object)
        })
    },
    filters: [
        {
            name: "scoringConfigurationsByHabFunctions",
            isDefault: false,
            async: true,
            query: function (context, callback) {
                global.app.S.User.getUserHabFunctions(
                    context.user,
                    context,
                    function (e, userHabFunctions) {
                        callback(
                            e,
                            {$or: [
                                    {habFunctions: {$size: 0}},
                                    {habFunctions: {$in: userHabFunctions.map(hf => new global.ObjectID(hf.id))}}
                                ]}
                        )
                    }
                )
            }
        }
    ]
}

export const module_ = {
    object: "ScoringConfig",
    tKey: "mTitle_scoringConfig",
    category: {
        path: 'setting',
        icon: 'settings'
    },
    defaultSortBy : "name",
    defaultSortDirection : "ASC",
    viewMap: {
        dt: [
            {path: "name", type: "translatedText", width: 300},
            {path: "filterFieldsName", tKey: "perimeter", width: 100},
            {path: "scoredElements", tKey: "scoredObject", display: "name", translateName: true, width: 100},
            {path: "comparisonAxis", tKey: "comparisonGroup", display: "tName", width: 50},
            {path: "timeWindow", tKey: "periodicity", display: "tKey", translate: true, width: 50},
            {path: "habFunctions", tKey: "profile(s)", translateName: true, width: 100, initiallyNotVisible: true},
            {path: "dormant", width: 50},
        ],
        form: {
            fields: [
                {path: "code"},
                {path: "name", type: "textarea"},
                "description",

                {path: "filterFields", tKey: "perimeter", display: "completeName"},
                {
                    path: "scoredElements",
                    tKey: "scoredObject",
                    display: "name",
                    translateName: true,
                    fieldPath: ["id", "name", "joinedEntity"],
                    subscriptions: {
                        onChange: (newValue, oldValue, {formInitialize, module, store}) => {
                            store.dispatch(
                                generateFetchFieldListAction(
                                    "m-S-scoringConfig.ScoringConfig_comparisonAxis",
                                    store.getState,
                                    'form',
                                    {
                                        data: {scoredObject: newValue}
                                    }
                                )
                            )
                        }
                    }
                },
                {
                    path: "comparisonAxis",
                    tKey: "comparisonGroup",
                    display: "tName",
                    filters: ["byScoredObject"]
                },
                {
                    path: "timeWindow",
                    tKey: "periodicity",
                    display: "tKey",
                    translate: true,
                    clearable: false,
                    default: {id: '00000000000000000000000a'},
                    subscriptions: {
                        onChange: (newValue, oldValue, {formInitialize, module, store}) => {
                            if (!formInitialize || true) {
                                const state = store.getState();
                                const element = _.get(state, "form.editObject.values.timeWindow");

                                const dataFields = getFieldsModuleForm(
                                    ['weekDay', 'monthDay'],
                                    module
                                )

                                if (element) {
                                    switch (element.id) {
                                        case '00000000000000000000000b':
                                            dataFields[1].setValue(null)
                                            break
                                        case '00000000000000000000000c':
                                            dataFields[0].setValue(null)
                                            break
                                        default :
                                            dataFields.forEach(field => field.setValue(null))

                                    }
                                }
                                store.dispatch(setFieldVisibility('e_weekDay', !element || element.id === "00000000000000000000000b"))
                                store.dispatch(setFieldVisibility('e_monthDay', !element || element.id === "00000000000000000000000c"))
                            }
                        }
                    }
                },
                {path: "fromFiscalYearStart", default: false},
                {path: "weekDay", tKey: "weekDayActivation", display: "id", translate: true, clearable: false},
                {path: "monthDay", tKey: "monthDayActivation", clearable: false},

                {path: "delay", tKey: "timeLagInDays", default: 1, required: true},
                // {path: "sendJournal", tKey: "includeInJournal"},

                {
                    path: "scoringFields",
                    tKey: "rules",
                    required: true,
                    viewMap: {
                        dt: [
                            {path: "numeratorTheme", tKey: "numerator", display: "completeName"},
                            {path: "numeratorFieldType", tKey: "nature", display: "tKey", translate: true},
                            {path: "denominatorTheme", tKey: "denominator", display: "completeName"},
                            {path: "denominatorFieldType", tKey: "amountOrNumber", display: "tKey", translate: true},
                            "coefficient"
                        ],
                        form: [
                            {path: "numeratorTheme", tKey: "numerator", display: "completeName", searchInList: true},
                            {path: "numeratorFieldType", tKey: "nature", display: "tKey", translate: true},
                            {path: "denominatorTheme", tKey: "denominator", display: "completeName", searchInList: true},
                            {path: "denominatorFieldType", tKey: "amountOrNumber", display: "tKey", translate: true},
                            "lookupGrowth",
                            {path: "coefficient", default: 1, required: true, wholePositiveNumber: true}
                        ]
                    }
                },
                {path: "habFunctions", tKey: "habilitation", translateName: true},
                "dormant",
                {path: 'hasCalculatedData', hidden: true}
            ],
            onOpen: ({ store }) => {
                const state = store.getState()
                const objectMode = state.ui.objectMode
                const hasCalculatedData = state.edit.object.data.hasCalculatedData

                const fields = [
                    'e_code', 'e_filterFields', 'e_scoredElements', 'e_comparisonAxis',
                    'e_scoringFields', 'e_timeWindow', 'e_alertFields'
                ]
                fields.forEach(field => store.dispatch(changeFieldDisabled(field, hasCalculatedData)))
            }
        }
    }
}
